html,
body,
#root,
.main-content {
  min-height: 100vh;
  position: relative;
}

.full {
  min-height: 600px;
  max-height: 600px;
}

#navbar-main {
  z-index: 0;
}

.header {
  z-index: -1;
}

.outline-white {
  color: white !important;
  border-color: white !important;
}

.exit-btn:hover {
  border-color: transparent !important;
  background-color: #d2311c !important;
}

.red {
  color: red;
}

.input-quantity {
  max-width: 75px;
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .btn-add {
    margin-top: 15px;
  }

  .col-quantity {
    min-width: 150px !important;
  }

  .input-quantity {
    max-width: 35px;
  }

  .footer {
    display: block;
  }

  .full {
    max-height: 1366px;
  }
}
